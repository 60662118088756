<template>
    <header v-if="navbar" class="header is-sticky" :class="{'theme-strategy': theme === 'strategy'}">
        <div id="mobile-nav" :class="{'mobile-content': true, 'menu-open': isSearchOpen}">
            <VJoyButton
                v-if="mobileMenuHasContent"
                size="small"
                variant="ghost"
                circle
                :icon="isMenuOpen ? 'cross' : 'bars'"
                :class="isMenuOpen ? 'close-icon' : 'menu-icon'"
                data-testid="burger-menu-cta"
                aria-label="Menu"
                @click="toggleMenu"
            />

            <a :href="logoLink">
                <MaltImg :src="logoUrl" :alt="logoAlt" :width="logoSizes.mobile.width" :height="logoSizes.mobile.height" class="header__logo" />
            </a>
            <div id="mobile-nav-search"></div>

            <VJoyButton
                v-if="isSearchPage || (searchIsVisible && (!user || !user.connected || isCompany))"
                circle
                size="small"
                variant="ghost"
                :icon="isSearchOpen ? 'cross' : 'search'"
                :class="isSearchOpen ? 'close-icon' : 'search-icon'"
                @click="toggleSearchModal"
            />

            <div v-else class="button-placeholder"></div>

            <div id="mobile-menu" :class="{modale: true, open: isMenuOpen}" data-testid="mobile-menu">
                <div v-if="user && user.connected" class="menus">
                    <div class="header-user-slice-wrapper">
                        <SliceZone :slices="slices.filter(filterFactory('header_user_slice'))" :components="components" />
                    </div>
                    <SliceZone :slices="slices.filter(filterFactory('user_settings_slice'))" :components="components" />
                </div>
                <template v-else>
                    <div class="menus">
                        <div class="header-megamenus" role="tablist">
                            <SliceZone :slices="slices.filter(filterFactory('megamenu'))" :components="components" :context="{mobile: true}" />
                        </div>
                        <SliceZone :slices="slices.filter(filterFactory('header_links_slice'))" :components="components" />
                    </div>
                    <div class="user-controls">
                        <div class="header-user-slice-wrapper">
                            <SliceZone
                                v-if="!user || !user.connected"
                                :slices="slices.filter(filterFactory('header_user_slice'))"
                                :components="components"
                            />
                        </div>
                        <CountrySelectionDialogTrigger :country-slice="countrySlice" :locale="locale!" />
                    </div>
                </template>
            </div>
            <div
                v-if="isSearchPage || (searchIsVisible && (!user || !user.connected || isCompany))"
                id="mobile-search"
                :class="{modale: true, open: isSearchOpen}"
                hide-backdrop
                hide-header
                hide-footer
            >
                <div class="mobile-search">
                    <SliceZone :slices="slices.filter(filterFactory('header_search_slice'))" :components="components" />
                </div>
                <div class="user-controls">
                    <div class="header-user-slice-wrapper">
                        <SliceZone
                            v-if="!user || !user.connected"
                            :slices="slices.filter(filterFactory('header_user_slice'))"
                            :components="components"
                        />
                    </div>
                    <CountrySelectionDialogTrigger :country-slice="countrySlice" :locale="locale!" />
                </div>
            </div>
        </div>
        <div class="desktop-content">
            <div class="header-links">
                <a :href="logoLink">
                    <MaltImg :src="logoUrl" :alt="logoAlt" :width="logoSizes.desktop.width" :height="logoSizes.desktop.height" class="header__logo" />
                </a>
                <template v-if="!isSearchPage && (!user || !user.connected)">
                    <div class="header-megamenus">
                        <SliceZone :slices="slices.filter(filterFactory('megamenu'))" :components="components" />
                    </div>
                    <SliceZone :slices="slices.filter(filterFactory('header_links_slice'))" :components="components" />
                </template>
            </div>

            <div id="header-customized-zone" class="header-customized-zone" data-testid="header-customized-zone">
                <div id="header-customized-zone-search"></div>
                <div v-if="isSearchPage && headerSearchSlice" class="search-banner">
                    <SliceZone :slices="[headerSearchSlice]" :components="components" />
                </div>
                <HeaderSearch
                    v-else-if="!user || !user.connected || isCompany"
                    v-show="searchIsVisible"
                    :header-search-slice="headerSearchSlice"
                    :search-placeholder-text="navbar?.data.searchPlaceholderText"
                />
                <div class="header-user-slice-wrapper">
                    <SliceZone :slices="slices.filter(filterFactory('header_user_slice'))" :components="components" />
                </div>
                <SliceZone :slices="slices.filter((slice) => slice.slice_type === 'user_settings_slice')" :components="components" />
                <CountrySelectionDialogTrigger :country-slice="countrySlice" :locale="locale!" />
            </div>
        </div>
    </header>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {callOnce, useAsyncData, useHead, useLocale, useRoute} from '#imports';
    import {VJoyButton} from '@maltjoy/core-vue';
    import CountrySelectionDialogTrigger from '@navbar-unlogged/components/CountrySelectionDialogTrigger.vue';
    import HeaderSearch from '@navbar-unlogged/components/HeaderSearch.vue';
    import {usePrismicDocument} from '@navbar-unlogged/composables';
    import {navbarUnloggedKeys} from '@navbar-unlogged/keys';
    import {components} from '@navbar-unlogged/slices';
    import {useABTestStore} from '@navbar-unlogged/store/abtest-unlogged.store';
    import {useUser} from '@navbar-unlogged/store/user.store';
    import {useSearchRouteDefinitions} from '@navbar/features/search';
    import type {CountrySliceSlice, HeaderSearchSliceSlice} from '@prismicio-types';
    import type {PrismicDocument, SharedSlice} from '@prismicio/types';
    import {SliceZone} from '@prismicio/vue';
    import {storeToRefs} from 'pinia';
    import type {PropType} from 'vue';
    import {computed, onBeforeUnmount, onMounted, provide, ref, watch} from 'vue';
    import {useFeatureFlagsStoreUnlogged} from './store/feature-flag-unlogged.store';

    type Theme = 'default' | 'strategy';

    const props = defineProps({
        showSearch: {type: Boolean, default: true},
        prismicDocument: {type: Object as PropType<PrismicDocument>},
        theme: {type: String as PropType<Theme>, default: 'default'},
    });

    const logoLink = ref('/');
    const logoUrl = ref('/cms-front/maltLogo.png');
    const logoAlt = ref('Malt Logo');
    const logoSizes = ref({mobile: {width: 125, height: 37}, desktop: {width: 110, height: 32}});

    if (props.theme === 'strategy') {
        logoLink.value = '/c/maltstrategy';
        logoUrl.value = '/cms-front/malt-strategy-logo.png';
        logoAlt.value = 'Malt Strategy Logo';
        logoSizes.value = {mobile: {width: 180, height: 28}, desktop: {width: 129, height: 20}};
    }

    // Composables
    const {locale} = useLocale();

    // Stores
    const {fetchCampaignsVariations} = useABTestStore();
    await fetchCampaignsVariations();
    const userStore = useUser();
    const {user, isCompany} = storeToRefs(userStore);
    const {loadFeatureFlags} = useFeatureFlagsStoreUnlogged();

    await callOnce('navbar-unlogged-user-fetch', async () => {
        await userStore.fetchUser();
    });
    await loadFeatureFlags();

    const navbar = ref<PrismicDocument | undefined | null>(props.prismicDocument);
    if (!navbar.value) {
        const {data} = await useAsyncData('malt-header', () => usePrismicDocument('malt-header', locale.value!));
        navbar.value = data.value;
    }
    provide(navbarUnloggedKeys.PRISMIC_DOCUMENT, navbar.value);

    const slices = computed<SharedSlice[]>(() => navbar.value?.data?.slices || []);
    const countrySlice = computed<CountrySliceSlice>(() => slices.value.find(filterFactory('country_slice')) as CountrySliceSlice);
    const headerSearchSlice = computed<HeaderSearchSliceSlice>(
        () => slices.value.find(filterFactory('header_search_slice')) as HeaderSearchSliceSlice,
    );
    const mobileMenuHasContent = computed(
        () => slices.value.filter(filterFactory('megamenu')).length || slices.value.filter(filterFactory('header_links_slice')).length,
    );

    const isSearchOpen = ref(false);
    const isMenuOpen = ref(false);

    const searchIsVisible = computed(() => navbar.value?.data.pageWithSearch && props.showSearch);

    const {currentPage} = useSearchRouteDefinitions();
    const isSearchPage = computed(() => currentPage.value?.name === 'Search');
    const bodyStyle = computed(() => (isSearchOpen.value ? 'overflow: hidden;' : ''));

    useHead({
        bodyAttrs: {
            style: bodyStyle,
        },
    });

    function toggleMenu() {
        isMenuOpen.value = !isMenuOpen.value;
        isSearchOpen.value = false;
    }

    function toggleSearchModal() {
        isSearchOpen.value = !isSearchOpen.value;
        isMenuOpen.value = false;
    }

    function filterFactory(sliceType: string): (slice: SharedSlice) => boolean {
        return (slice: SharedSlice) => slice.slice_type === sliceType;
    }

    function onSearchRequested() {
        isSearchOpen.value = false;
    }

    const route = useRoute();
    watch(
        () => route.name,
        () => {
            isMenuOpen.value = false;
        },
    );

    onMounted(() => {
        document.addEventListener('navbar:searchRequested', onSearchRequested);
    });

    onBeforeUnmount(() => {
        document.removeEventListener('navbar:searchRequested', onSearchRequested);
    });
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    .header {
        width: 100%;
        background: white;
        border-bottom: solid 1px var(--joy-color-neutral-30);
        font-family: var(--joy-font-family);
        padding: 6px var(--joy-core-spacing-9);

        &__logo {
            display: block;
        }

        &.is-sticky {
            position: sticky;
            top: 0;
            left: 0;
            z-index: var(--joy-core-z-index-fixed-content);
            height: 72px;

            @include mq.screen_sm {
                height: 56px;
            }
        }

        @include mq.screen_md {
            padding: 6px var(--joy-core-spacing-7);
        }
        @include mq.screen_sm {
            height: 56px;
            padding: 6px var(--joy-core-spacing-3);
        }

        .desktop-content {
            text-align: center;
            @include vars.flex-row-align-center;
            justify-content: space-between;
            align-content: center;
            height: 100%;

            @include mq.screen_md {
                display: none;
            }

            .header-megamenus {
                display: flex;
                justify-content: flex-start;
            }

            .header-customized-zone {
                display: flex;
                justify-content: flex-end;
                gap: 12px;
            }
        }

        .mobile-content {
            @include vars.flex-row-align-center;
            justify-content: space-between;
            height: 100%;
            display: none;

            @include mq.screen_md {
                display: flex;
            }

            .button-placeholder {
                width: 36px;
            }
        }

        &__funnel {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }

    :deep(.modale) {
        position: fixed;
        top: var(--header-height);
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        opacity: 0;
        background-color: white;
        padding: 10px 20px 40px;
        z-index: var(--joy-core-z-index-dialog);
        overflow: auto;

        &.open {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            opacity: 1;
            animation: fadeIn var(--joy-transition-duration-default);
        }

        .freelance-search {
            box-shadow: none;
            padding: 0;
        }

        .menus {
            z-index: 1;
        }

        .user-controls {
            display: flex;
            flex-direction: column;
            align-items: center;
            .header-user-slice-wrapper {
                width: 100%;
            }
        }
    }

    .header-links {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .search-banner {
        position: absolute;
        top: var(--header-height);
        left: 0;
        width: 100%;
        height: var(--search-banner-unlogged-height);
        margin: 0;
        padding: 0;
        background-color: white;
        border-bottom: solid 1px var(--joy-color-neutral-30);

        :global(:root) {
            --search-banner-unlogged-height: 72px;

            @include mq.screen_md {
                --search-banner-unlogged-height: 0px;
            }
        }
    }
</style>

<style>
    #mobile-nav-search {
        display: none;
    }
</style>
