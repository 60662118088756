<template>
    <div ref="dropdownRef" class="dropdown">
        <button :id="id" type="button" :class="{'dropdown-header': true, open: isOpen}" :aria-expanded="isOpen" @click="toggleDropdown">
            <slot name="header"></slot>
        </button>
        <div role="region" :class="{'dropdown-content': true, open: isOpen, [menuClass]: true}" :aria-labelledby="id">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
    import type {Ref} from 'vue';
    import {ref, onMounted, onBeforeUnmount} from 'vue';

    const isOpen = ref(false);
    const dropdownRef: Ref<HTMLDivElement | null> = ref(null);

    defineProps({
        id: {
            type: String,
            required: true,
        },
        menuClass: {
            type: String,
            default: '',
        },
    });

    function toggleDropdown() {
        isOpen.value = !isOpen.value;
    }

    function closeDropdown() {
        isOpen.value = false;
    }

    function clickOutsideDropDownListener(event: MouseEvent) {
        if (isOpen.value && !dropdownRef.value?.contains(event.target as Node)) {
            isOpen.value = false;
        }
    }

    onMounted(() => {
        document.addEventListener('click', clickOutsideDropDownListener);
        window.addEventListener('Navigation', closeDropdown);
    });

    onBeforeUnmount(() => {
        document.removeEventListener('click', clickOutsideDropDownListener);
        window.removeEventListener('Navigation', closeDropdown);
    });
</script>

<style lang="scss" scoped>
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    .dropdown {
        position: relative;

        .dropdown-header {
            background-color: transparent;
            width: 100%;
            padding: 0;
            border: none;
            @include vars.flex-row-align-center;
            justify-content: space-between;
            cursor: pointer;
        }

        .dropdown-content {
            transition: opacity var(--joy-transition-duration-normal) var(--joy-transition-timing-function);
            position: absolute;
            top: calc(100% + 40px);
            background-color: white;
            box-shadow: var(--joy-core-elevation-3);
            opacity: 0;
            display: none;
            z-index: var(--joy-core-z-index-dropdown);

            &.open {
                display: block;
                opacity: 1;
                transition: opacity var(--joy-transition-duration-normal) var(--joy-transition-timing-function) display 0
                    var(--joy-transition-duration-normal) var(--joy-transition-timing-function);
            }
        }
    }
</style>
