<template>
    <div v-if="navbarSearchPanelV2" id="header-search">
        <VJoyDialogTrigger :dialog="dialog">
            <VJoyButton class="header-search-button" size="small" data-testid="navbar-search-button" icon="search" variant="secondary">
                {{ searchPlaceholderText }}
            </VJoyButton>
        </VJoyDialogTrigger>

        <VJoyDialog ref="dialog" class="header-search-dialog">
            <template #dialog-body>
                <div class="menu-content" role="menuitem" aria-label="search-pannel">
                    <SliceZone v-if="headerSearchSlice" :slices="[headerSearchSlice]" :components="components" />
                </div>
            </template>
        </VJoyDialog>
    </div>
    <Dropdown v-else id="header-search" class="search-dropdown" menu-class="menu">
        <template #header>
            {{ searchPlaceholderText }}
        </template>
        <template #content>
            <div class="backdrop"></div>
            <div class="menu-content" role="menuitem" aria-label="search-pannel">
                <SliceZone v-if="headerSearchSlice" :slices="[headerSearchSlice]" :components="components" />
            </div>
        </template>
    </Dropdown>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import {SliceZone} from '@prismicio/vue';
    import {VJoyButton, VJoyDialog, VJoyDialogTrigger} from '@maltjoy/core-vue';
    import Dropdown from '@navbar-unlogged/components/Dropdown.vue';
    import type {HeaderSearchSliceSlice} from '@prismicio-types';
    import {components} from '@navbar-unlogged/slices';
    import {useFeatureFlagsStoreUnlogged} from '@navbar-unlogged/store/feature-flag-unlogged.store';

    interface Props {
        headerSearchSlice: HeaderSearchSliceSlice;
        searchPlaceholderText: String;
    }
    defineProps<Props>();

    const dialog = ref<InstanceType<typeof VJoyDialog>>();

    const {features} = useFeatureFlagsStoreUnlogged();
    const navbarSearchPanelV2 = features['navbar-search-panel-v2'];
</script>

<style lang="scss" scoped>
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    .search-dropdown {
        position: static !important;
        z-index: var(--joy-core-z-index-dialog);
        pointer-events: all;

        :deep(> button) {
            @include vars.input;
            height: 100%;
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"%3E%3Cpath d="M12.5578 12.5583L16.9995 17M13.7796 6.26994C15.0166 9.18048 13.6599 12.5427 10.7493 13.7796C7.83879 15.0166 4.47659 13.6599 3.23964 10.7493C2.00269 7.83879 3.3594 4.47659 6.26994 3.23964C9.18048 2.00269 12.5427 3.3594 13.7796 6.26994Z" fill="none" stroke="black" stroke-width="1"/%3E%3C/svg%3E');
            background-repeat: no-repeat;
            background-size: 19px 19px;
            background-position: 20px center;

            font-size: var(--joy-font-size-primary-300);
            line-height: 100%;
            border-color: var(--joy-color-secondary-30) !important;

            padding: 8px 16px 8px 48px !important;
            color: var(--joy-color-secondary-30) !important;
            font-weight: var(--joy-font-weight-normal);
            border-color: var(--joy-color-secondary-30);
            background-color: white !important;
            box-shadow: none;

            &[aria-expanded='true'] {
                color: var(--joy-color-neutral-50) !important;
                border-color: var(--joy-color-neutral-30) !important;
                background-image: url('//dam.malt.com/navbar/icons/header/unlogged/search-gray.svg');
            }

            &:focus {
                box-shadow: none !important;
            }
        }

        .backdrop {
            position: absolute;
            pointer-events: none;
            z-index: var(--joy-core-z-index-backdrop);
            top: 0;
            height: calc(100vh - var(--header-height));
            left: 0;
            right: 0;
            background-color: var(--joy-color-overlay);
            transition: opacity var(--joy-transition-duration-long) var(--joy-transition-timing-function);
        }

        :deep(.menu) {
            width: calc(100vw - (100vw - 100%)); //100vw - scrollbar width
            margin: 0;
            border: none;
            border-radius: 0;
            padding: 0;
            top: var(--header-height) !important;
            left: 0;
        }

        .menu-content {
            position: relative;
            z-index: var(--joy-core-z-index-dialog);
            background-color: white;
        }
    }
</style>

<style lang="scss">
    .header-search-button {
        padding: 0 var(--joy-core-spacing-4) !important;
    }
    .header-search-dialog {
        padding: 0 !important;
        .joy-dialog--header {
            height: 72px;
            box-shadow: var(--joy-core-elevation-1);
        }
        #joy-dialog--close {
            top: var(--joy-core-spacing-5) !important;
        }
        .joy-dialog--wrapper {
            animation: none !important;
            transition: none !important;
            transform: none !important;
            opacity: 1 !important;
        }
        .joy-dialog--body {
            overflow: visible;
        }
        .joy-dialog__inner {
            width: 100%;
            height: 100%;
            max-height: 100%;
            padding: 0;
            border-radius: 0;
        }
    }
</style>
