import {defineStore} from 'pinia';
import {ref, computed} from 'vue';
import {FetchError} from 'ofetch';
import type {UserInfo} from '@navbar-unlogged/types/custom';
import {useLogger, useUniversalFetch} from '#imports';

export const useUser = defineStore('navbar-unlogged-user', () => {
    const user = ref<UserInfo | null>(null);

    async function fetchUser() {
        const logger = useLogger();

        try {
            const data = await useUniversalFetch<UserInfo>('/api/user/me');
            data.connected = !!data.username;
            user.value = data;
        } catch (err) {
            if (err instanceof FetchError && err.statusCode! >= 500) {
                logger.error(err);
            }
            user.value = null;
        }

        return user.value;
    }

    const isCompany = computed(() => {
        if (!user.value) {
            return false;
        }
        return user.value.identities.find((identity) => identity.selected)?.identityType === 'CLIENT';
    });

    return {
        user,
        fetchUser,
        isCompany,
    };
});
