import type {KeyTextField, ImageField} from '@prismicio/types';
import {useTypeGuards} from './useTypeGuards';

const {hasProperty} = useTypeGuards();

export type Country = {
    countryName: KeyTextField;
    countryKey: KeyTextField;
    lang1Name: KeyTextField;
    lang1Key: KeyTextField;
    lang2Name: KeyTextField;
    lang2Key: KeyTextField;
    lang3Name: KeyTextField;
    lang3Key: KeyTextField;
    countryIcon: ImageField;
};

function getCountry(countries: Country[] = [], countryKey?: string): Country | undefined {
    if (!countryKey) {
        return undefined;
    }
    return countries.find((country) => {
        if (!hasProperty(country, 'countryKey')) {
            return false;
        }
        if (typeof country.countryKey !== 'string') {
            return false;
        }
        return country.countryKey.toUpperCase() === countryKey.toUpperCase();
    });
}

export function useCountry() {
    return {
        getCountry,
    };
}
