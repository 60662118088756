import mitt from 'mitt';

function createEventBus() {
    const emitter = mitt();
    const originalOnFunction = emitter.on;

    emitter.on = function (...args) {
        if (typeof window !== 'undefined') {
            originalOnFunction.apply(this, args);
        }
    };

    return emitter;
}

export {createEventBus};
