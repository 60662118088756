import type {RouteResolversFn} from '@navbar/common/composables';
import {useLocationWatcher} from '@navbar/common/composables';
import {computed} from 'vue';
import {SearchDisplayMode} from '../search.types';

export type PossibleSearchPages = 'Profile' | 'Playground' | 'Search' | 'AllFreelances' | 'Seo-tags' | 'find-freelancers' | 'Job-description';

export type PagePattern = {
    name: PossibleSearchPages;
    isActiveWhen: (resolvers: RouteResolversFn) => boolean;
    /**
     * Pass to true if you don't want header to overlap on content
     * @default false
     */
    pushContent?: boolean;
    searchDisplayMode: SearchDisplayMode;
};

export function useSearchRouteDefinitions() {
    const {routeUtils} = useLocationWatcher();

    const pagesPatterns: PagePattern[] = [
        {
            name: 'Profile',
            searchDisplayMode: SearchDisplayMode.FORM,
            pushContent: true,
            isActiveWhen: ({startWith}) => startWith('/profile'),
        },
        {
            name: 'Playground',
            searchDisplayMode: SearchDisplayMode.FORM,
            isActiveWhen: ({exact}) => exact('/navbar-playground/'),
        },
        {
            name: 'Search',
            searchDisplayMode: SearchDisplayMode.FORM,
            pushContent: true,
            isActiveWhen: ({exact}) => exact('/s'),
        },
        {
            name: 'AllFreelances',
            searchDisplayMode: SearchDisplayMode.FORM,
            isActiveWhen: ({exact}) => exact('/all-freelances'),
        },
        {
            name: 'Seo-tags',
            searchDisplayMode: SearchDisplayMode.FORM,
            isActiveWhen: ({startWith}) => startWith('/s/tags/'),
        },
        {
            name: 'find-freelancers',
            searchDisplayMode: SearchDisplayMode.NONE,
            isActiveWhen: ({exact}) => exact('/project-client/find-freelancers'),
        },
        {
            name: 'Job-description',
            searchDisplayMode: SearchDisplayMode.NONE,
            isActiveWhen: ({startWith}) => startWith('/s/advanced'),
        },
    ];

    const currentPage = computed(() => pagesPatterns.find((f) => !!f.isActiveWhen(routeUtils)));

    return {
        currentPage,
    };
}
