<template>
    <div class="country-selection unlogged">
        <VJoyDialogTrigger :dialog="countrySelectionDialog">
            <button class="country-button" data-testid="navbar-country-button">
                <OptimizedImage
                    v-if="!!currentCountry"
                    :prismic-image="currentCountry.countryIcon"
                    height="14"
                    width="14"
                    :alt="currentCountry.lang1Name"
                />
                {{ lang.toUpperCase() }}
            </button>
        </VJoyDialogTrigger>
        <VJoyDialog id="countrySelectionDialog" ref="countrySelectionDialog" data-testid="country-selection-dialog" @dialog:open="onDialogShow">
            <template #dialog-header>
                {{ titleField.text }}
            </template>
            <template #dialog-subheader>
                {{ subtitleField.text }}
            </template>
            <template #dialog-body>
                <SliceZone v-if="countrySlice" :slices="[countrySlice]" :components="components" />
            </template>
        </VJoyDialog>
    </div>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import OptimizedImage from './OptimizedImage.vue';
    import {useCountry} from '@navbar-unlogged/composables/useCountry';
    import type {CountrySliceSlice} from '@prismicio-types';
    import {eventBus} from '@navbar-unlogged/events';
    import {VJoyDialog, VJoyDialogTrigger} from '@maltjoy/core-vue';
    import {SliceZone} from '@prismicio/vue';
    import {components} from '@navbar-unlogged/slices';

    const {getCountry} = useCountry();

    type Props = {
        countrySlice?: CountrySliceSlice;
        locale?: string;
    };
    const props = defineProps<Props>();

    const countrySelectionDialog = ref(null);
    const titleField = ref(props.countrySlice?.primary?.countrySelectionTitle[0] as {text: string});
    const subtitleField = ref(props.countrySlice?.primary?.description[0] as {text: string});

    const [lang, countryCode] = props.locale?.split('-') ?? ['en'];

    const currentCountry = ref(props.countrySlice && getCountry(props.countrySlice.items, countryCode));

    function onDialogShow() {
        eventBus.emit('navbar-unlogged:reset-selected-host');
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    .country-selection {
        margin-left: 20px;
        display: flex;
        align-items: center;

        &.unlogged {
            @include mq.screen_md {
                margin: 0px;
                .country-button {
                    transform: translate(calc(25vw - 13px), -28px);
                }
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            color: var(--joy-color-neutral-60);
        }

        img {
            width: 14px;
            height: 14px;
            object-fit: cover;
            object-position: center;
            overflow: hidden;
        }

        button.country-button {
            background-color: transparent;
            border: none;
            line-height: vars.$line-height-xl;
            color: var(--joy-color-neutral-50);
            position: relative;
            padding: 0;

            &:hover,
            &:focus,
            &:active,
            &:focus,
            &.btn-secondary:not(:disabled):not(.disabled):active {
                background-color: transparent;
                color: var(--joy-color-neutral-60);
                border: none;
                box-shadow: none;
            }

            &:after {
                background: none repeat scroll 0 0 transparent;
                background: var(--joy-color-secondary-30);
                content: '';
                display: block;
                left: 50%;
                position: absolute;
                bottom: -13px;
                transition: width var(--joy-transition-duration-default) var(--joy-transition-timing-function),
                    left var(--joy-transition-duration-default) var(--joy-transition-timing-function);
                width: 0;
                height: 4px;
                border-radius: 4px;
            }

            &:hover:after {
                width: 100%;
                left: 0;
            }
        }
    }
</style>
